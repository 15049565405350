<template>

  <div :id="'decision-time-counter-'+version" class="mr-20 time-counter">
    <div class="w-100 time-counter-field">
      <div class="card-body">
        <div class="card-title align-right counter-title">{{ $t('wz_case_form.decision_time_counter.title').toUpperCase() }}</div>
        <div v-if="wz_case.date_to_decision.length > 0">
            <table class="w-25 mt-1">
              <tr>
                <td class="w-auto">
                  <span :id="'days-'+version" class="grey-block">00</span>
                </td>
                <td class="w-auto">
                  <span :id="'hours-'+version" class="grey-block">00</span>
                </td>
                <td class="w-auto">
                  <span :id="'minutes-'+version" class="grey-block">00</span>
                </td>
                <td class="w-auto ">
                  <span :id="'seconds-'+version" class="grey-block">00</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span class="text-block">{{ $t('wz_case_form.decision_time_counter.days_label') }}</span>
                </td>
                <td>
                  <span class="text-block">{{ $t('wz_case_form.decision_time_counter.hours_label') }}</span>
                </td>
                <td>
                  <span class="text-block">{{ $t('wz_case_form.decision_time_counter.minutes_label') }}</span>
                </td>
                <td>
                  <span class="text-block">{{ $t('wz_case_form.decision_time_counter.seconds_label') }}</span>
                </td>
              </tr>
            </table>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DecisionTimeCounterNewStyle",
  computed: {
    ...mapGetters('WzCase', {wz_case: 'wz_case'}),
  },
  props: {
    timer: Boolean,
    version: String
  },
  data() {
    return {
      timeLeft: 0,
    }
  },
  methods: {
    runTimer() {

        let endTime = new Date(this.wz_case.date_to_decision);
        endTime = (Date.parse(endTime) / 1000);
        let now = new Date();
        now = (Date.parse(now) / 1000);
        this.timeLeft = endTime - now;

        if(this.timeLeft > 0) {
          let days = Math.floor(this.timeLeft / 86400);
          let hours = Math.floor((this.timeLeft - (days * 86400)) / 3600);
          let minutes = Math.floor((this.timeLeft - (days * 86400) - (hours * 3600)) / 60);
          let seconds = Math.floor((this.timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
          if (days < "10") {
            days = "0" + days;
          }
          if (hours < "10") {
            hours = "0" + hours;
          }
          if (minutes < "10") {
            minutes = "0" + minutes;
          }
          if (seconds < "10") {
            seconds = "0" + seconds;
          }
          document.getElementById("days-" + this.version).innerHTML = days.toString();
          document.getElementById("hours-" + this.version).innerHTML = hours.toString();
          document.getElementById("minutes-" + this.version).innerHTML = minutes.toString();
          document.getElementById("seconds-" + this.version).innerHTML = seconds.toString();
        }
        setTimeout(this.runTimer, 1000);
    }
  },
  mounted() {
    if (this.timer) {
      this.runTimer();
    }
  }
}
</script>

<style scoped>
.mr-20 {
   padding-right:0;
 }
 .grey-block {
   width: 37px;
   height: 37px;
   display: block;
   background-color: #E6E6E6;
   align-content: center;
   text-align: center;
   color: #4E4E4E;
   font-size: 14px;
   margin-left: 10px;
   font-weight: 700;
 }
.text-block {
  width: 37px;
  display: block;
  align-content: center;
  text-align: center;
  font-size: 12px;
  margin-left: 10px;
}
 .counter-title {
   font-family: Mulish;
   font-size: 12px;
   font-weight: 700;
   line-height: 15.06px;
   text-align: right;

 }
.time-counter {
  width: 100%;
}
@media (max-width: 768px) {
  .time-counter {
    align-content: center;
    float: unset;
    text-align: center;
    margin-top: 30px;
  }
  .card-body table {
    margin: auto;
  }
  .counter-title {
    float: unset;
    text-align: center;
  }

}
@media (min-width: 768px) {
  .time-counter {
    float: left;
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .time-counter-field {
    bottom: 10px;
  }
  .card-body table {
    float: right;
   }
  .right-0 {
    right: 0;
  }
}
</style>