export const Wz2FormValidator = {
    data() {
        return {
            v_error: false,
            v_is_valid: false,
            v_validation_error: null,
        }
    },
    methods: {
        validateForm: function (data) {
            if (data.fax_id === 109) {
                if (data.files.length === 0 && data.decision === '') {
                    this.v_is_valid = false;
                    this.v_error = true;
                    this.v_validation_error = this.$t('validations.files_none');
                    return false;
                }
                if (data.files.length > 0) {
                    return this.v_validateFilesWithFilms(data.files)
                }
                if (data.decision !== '') {
                    return true;
                }
            } else if (data.current_form === '' || typeof(data.current_form) === 'undefined' || data.current_form === null) {
                this.v_validation_error = this.$t('validations.unexpected_mistake');
            } else {
                switch (data.current_form) {
                    case 'A':
                    case 'B':
                        if (data.files.length === 0 && data.decision === '') {
                            this.v_is_valid = false;
                            this.v_error = true;
                            this.v_validation_error = this.$t('validations.files_none');
                            return false;
                        }
                        if (data.files.length > 0) {
                            return this.v_validateFiles(data.files)
                        }
                        if (data.decision !== '') {
                            return true;
                        }
                        break;
                    case 'C':
                        if (data.imei.trim() === '' && data.sn.trim() === '') {
                            this.v_is_valid = false;
                            this.v_error = true;
                            this.v_validation_error = this.$t('validations.field_empty');
                            return false;
                        }
                        if (data.imei.trim() !== '') {
                            return this.v_validateImei(data.imei.trim())
                        }
                        if (data.sn.trim() !== '') {
                            return this.v_validateSn(data.sn.trim())
                        }
                        break;
                    case 'D':
                        return this.v_validateFaultDescription(data.fault_description);
                    case 'E':
                    case 'F':
                    case 'H':
                        return this.v_validateDecision(data.decision);
                }
            }
        },

        v_validateImei: function (imei) {
            if (imei.length !== 15) {
                this.v_is_valid = false;
                this.v_error = true;
                this.v_validation_error = this.$t('validations.imei_too_long');
                return false;
            } else if (!imei.match(/^[0-9]+$/)) {
                this.v_is_valid = false;
                this.v_error = true;
                this.v_validation_error = this.$t('validations.imei_num_let');
                return false;
            } else {
                this.v_is_valid = true;
                this.v_error = false;
                this.v_validation_error = null;
                return true;
            }
        },
        v_validateSn: function (sn) {
            /** SN VALIDATION */
            if (sn.length > 20) {
                this.v_is_valid = false;
                this.v_error = true;
                this.v_validation_error = this.$t('validations.sn_too_long');
                return false;
            } else if (sn.length < 11) {
                this.v_is_valid = false;
                this.v_error = true;
                this.v_validation_error = this.$t('validations.sn_too_short');
                return false;
            } else if (!sn.match(/^[-_/\\&0-9a-zA-Z]+$/)) {
                this.v_is_valid = false;
                this.v_error = true;
                this.v_validation_error = this.$t('validations.sn_num_let');
                return false;
            } else {
                this.v_is_valid = true;
                this.v_error = false;
                this.v_validation_error = null;
                return true;
            }
        },
        v_validateFaultDescription: function (fault_description) {
            /** FAULT DESCRIPTION VALIDATION */
            if (typeof(fault_description) === 'undefined' || fault_description === null || fault_description.trim() === "") {
                this.v_is_valid = false;
                this.v_error = true;
                this.v_validation_error = this.$t('validations.fault_description_empty');
                return false;
            } else if (fault_description.length < 5) {
                this.v_is_valid = false;
                this.v_error = true;
                this.v_validation_error = this.$t('validations.fault_description_short');
                return false;
            } else {
                this.v_is_valid = true;
                this.v_error = false;
                this.v_validation_error = null;
                return true;
            }
        },
        v_validateDecision: function (decision) {
            /** FAULT complaint type */
            if (typeof (decision) === 'undefined' || decision === null || decision === '') {
                this.v_is_valid = false;
                this.v_error = true;
                this.v_validation_error = this.$t('validations.memory_decision_empty');
                return false;
            } else {
                this.v_is_valid = true;
                this.v_error = false;
                this.v_validation_error = null;
                return true;
            }
        },
        v_validateFiles: function (files) {
            let allowedTypes = ['application/pdf','image/jpg','image/jpeg','image/png'];
            let error = '';
            if (files.length > 5) {
                error = this.$t('validations.max_files_amount');
            } else {
                for (let i= 0; i < files.length; i++) {
                    if (!allowedTypes.includes(files[i].type)) {
                        error = this.$t('validations.files_disallowed');
                        break;
                    }
                    if (files[i].size > 999000) {
                        error = this.$t('validations.file_too_big');
                        break;
                    }
                }
            }
            if (error !== '') {
                this.v_is_valid = false;
                this.v_error = true;
                this.v_validation_error = error;
                return false;
            } else {
                this.v_is_valid = true;
                this.v_error = false;
                this.v_validation_error = null;
                return true;
            }
        },
        v_validateFilesWithFilms: function (files) {
            let allowedTypes = ['application/pdf','image/jpg','image/jpeg','image/png', 'video/mp4'];
            let error = '';
            for (let i= 0; i < files.length; i++) {
                if (!allowedTypes.includes(files[i].type)) {
                    error = this.$t('validations.files_disallowed_film');
                    break;
                }
            }
            if (error !== '') {
                this.v_is_valid = false;
                this.v_error = true;
                this.v_validation_error = error;
                return false;
            } else {
                this.v_is_valid = true;
                this.v_error = false;
                this.v_validation_error = null;
                return true;
            }
        }
    }
}