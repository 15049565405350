import {mapActions, mapGetters} from "vuex";
import {Wz2FormValidator} from "@/mixins/Wz2FormValidator";
import {Wz1FormValidator} from "@/mixins/Wz1FormValidator";

export const Wz2FormProcess = {
    data() {
        return {
            timer: true,
        }
    },
    mixins: [Wz2FormValidator, Wz1FormValidator],
    computed: {
        ...mapGetters('WzCase', {
            wz_case: 'wz_case',
            wz_case_decision: 'wz_case_decision',
            payment_transaction: 'payment_transaction',
            attachments: 'attachments',
            user_ip: 'user_ip',
        }),
        ...mapGetters('Wz2Form', {
            wz2_form: 'wz2_form',
            process_loading: 'loading',
            process_error: 'error',
            attachment_files: 'attachment_files'
        }),
    },
    methods: {
        ...mapActions('Wz2Form', {
            updateWz2Form: 'updateWz2Form', 
            storeWzCaseDecision: 'storeWzCaseDecision',
            uploadWzCaseAttachments: 'uploadWzCaseAttachments',
            setWz2Form: 'setWz2Form'
        }),
        saveWz2Form: function () {
            let data = this.params;
            if (this.validateForm(data)) {
                if (data.files.length > 0) {
                    this.submitWz2CaseDecisionWithAttachments(data.files);
                } else {
                    let decision = '';
                    if (data.decision !== '') {
                        decision = data.decision;
                    } else if (data.fault_description !== '') {
                        decision = 'Opis usterki: ' + data.fault_description;
                    } else if (data.complaint_type !== '') {
                        decision = 'Zgłaszam reklamację z tytułu: ' + data.complaint_type;
                    } else if (data.imei !== '') {
                        decision = 'Numer IMEI: ' + data.imei;
                    } else if (data.sn !== '') {
                        decision = 'Numer seryjny: ' + data.sn;
                    }
                    this.submitWz2CaseDecision(decision);
                }
            }
        },
        validateWz2BeforeShowSendButton: function () {
            let data = this.params;
            return !!this.validateForm(data);
        },

        /**
         * Submit (send to backend) selected decision
         */
        submitWz2CaseDecision: function (decision_message) {
            this.updateWz2Form({
                key: 'decision_message',
                value: decision_message
            });
            this.updateWz2Form({
                key: 'decision',
                value: 'yes'
            });
            let storeStatus =
                this.storeWzCaseDecision();
            const checkStatus = async () => {
                const status = await storeStatus;
                if (status) {
                    this.$router.push('success');
                }
            };
            checkStatus();
        },
        /**
         * Submit (send to backend) selected decision
         */
        submitWz2CaseDecisionWithAttachments: function (attachment_files) {
            let form = new FormData();
            for (let i = 0; i < attachment_files.length; i++) {
                form.append('file_' + i, attachment_files[i], attachment_files[i]['name']);
            }
            form.append('case_guid', this.wz_case.case_guid)
            if (this.uploadWzCaseAttachments(form)) {
                this.submitWz2CaseDecision('Dokumenty dodane');
            }
        }
    }
}