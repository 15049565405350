<template>
  <div id="wz2-form">
    <div class="container-fluid mb-2">
      <div class="row">
        <app-service-order-data-new/>
      </div>
      <div v-if="wz_case.active">
        <div class="row main-subtitle">
          <div>
            {{ $t('wz_case_form.subtitle_wz_2') }}
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-xxl-12 mt-2 mb-2">
            <div class="row g-2 card-group">
              <div class="col-12 col-md-12 col-xxl-12">
                <app-wz2-details/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <app-no-wz-case-found/>
      </div>
    </div>
  </div>

</template>

<script>

import {Wz2FormProcess} from "@/mixins/Wz2FormProcess";
import {mapActions} from "vuex";

export default {
  name: "Wz2Form",
  mixins: [Wz2FormProcess],

  methods: {
    ...mapActions('Wz2Form', {setWz2Form:'setWz2Form'}),
  },
  mounted() {
    /** Set WzCase form case_guid */
    this.updateWz2Form({
      key: 'case_guid',
      value: this.wz_case.case_guid
    })
    /** Set WzCase form case_guid */
    this.updateWz2Form({
      key: 'ip',
      value: this.user_ip
    })
  }
}
</script>

<style scoped>
#wz2-form {
  margin-left: 5%;
  margin-right: 5%;
}
.main-subtitle {
  display: inline-block;
  margin-top: 40px;
  margin-bottom: 20px;
  font-family: Mulish;
  font-size: 24px;
  font-weight: 700;
  line-height: 30.12px;
  text-align: center;
}
.navbar-brand img{
  height: 37px;
}
@media (min-width: 240px) {
  .main-subtitle {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;

  }
}
@media (min-width: 768px) {
  .main-subtitle {
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    text-align: center;
    margin-top: 60px;
  }
}
</style>