<template>

  <div class="thanks">
    {{ $t('wz_case_form.wz2_form.success.thanks') }}
  </div>
  <div class="for-sent">
    {{ $t('wz_case_form.wz2_form.success.for_sent') }}
  </div>
  <div class="answer">
    {{ $t('wz_case_form.wz2_form.success.answer1') }}
    <span class="answer-bold">
      <a :href="`mailto:info@ccsonline.pl`">info@ccsonline.pl</a>
    </span>
    {{ $t('wz_case_form.wz2_form.success.answer3') }}
    <span class="answer-bold">{{ $t('wz_case_form.wz2_form.success.answer4') }}</span>
  </div>
  <div class="answer">
    {{ $t('wz_case_form.wz2_form.success.check') }}
  </div>
  <div class="check-button">
    <a href="https://www2.ccsonline.pl/status/status.php">{{ $t('wz_case_form.wz2_form.success.check_button') }}</a>
  </div>

</template>

<script>

export default {
  name: "Success",
  props: {
    message: {
      type: String,
      default: "default",
    }
  }
}
</script>

<style scoped>
  .thanks {
    margin-top: 130px;
    margin-bottom: -10px;
    width: 100%;
    color: #23AE84;
    font-family: Mulish;
    font-size: 48px;
    text-align: center;
    font-weight: 900;
    text-shadow: 3px 0 #23AE84;
    letter-spacing: 2px;
  }
  .for-sent {
    margin-top: 0;
    width: 100%;
    color: #2A4A40;
    font-family: Mulish;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    text-shadow: 2px 0 #2A4A40;
    letter-spacing: 2px;
  }
  .answer {
    margin-top: 35px;
    width: 90%;
    margin-left: 5%;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: center;
    color: #444444;
    margin-bottom: 20px;
  }
  .answer-bold {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    text-align: center;
    color: #444444;
  }
  .answer-bold a {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    text-align: center;
    text-decoration: none;
    color: #444444;
  }
  .check-button {
    width: 251px;
    height: 42px;
    padding: 11px 30px 11px 30px;
    border-radius: 25px;
    display: block;
    margin: auto;
    background-color: #23AE84;
    color: #FFFFFF;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.08px;
    text-align: center;
  }
  .check-button a {
    text-decoration: none;
    color: white;
  }
  @media (max-width: 768px) {
    .check-button {
      width: 75%;
      margin: auto;
    }
  }
</style>