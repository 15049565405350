<template>

  <div id="service-order-data" class="row h-100 pr-0" v-if="wz_case.active">
    <div class="col-ms-12 col-md-8 col-xxl-8 col-lg-8" >
      <div class="card-body service-order-data">
        <div class="row row1">
          <div class="half-forth-part-label" >
            {{ $t('wz_case_form.service_order_data.model') }}
          </div>
          <div class="half-forth-part-value value" >
            {{ wz_case.maker }} {{ (wz_case.device_business_name) ? wz_case.device_business_name : wz_case.invent_model_id }}
          </div>
          <div class="half-forth-part-label show-mobile" >
            {{ $t('wz_case_form.service_order_data.service_order_id') }}
          </div>
          <div class="half-forth-part-value value show-mobile" >
            {{ wz_case.service_order_id }}
          </div>
          <div class="half-forth-part-label show-desktop" >
            {{ $t('wz_case_form.service_order_data.pos_number') }}
          </div>
          <div class="half-forth-part-value value show-desktop" >
            {{ wz_case.pos_number }}
          </div>
        </div>
        <div class="row">
          <div class="half-forth-part-label show-mobile" >
            {{ $t('wz_case_form.service_order_data.pos_number') }}
          </div>
          <div class="half-forth-part-value value show-mobile" >
            {{ wz_case.pos_number }}
          </div>
          <div class="half-forth-part-label show-desktop" >
            {{ $t('wz_case_form.service_order_data.service_order_id') }}
          </div>
          <div class="half-forth-part-value value show-desktop" >
            {{ wz_case.service_order_id }}
          </div>
          <div class="half-forth-part-label" >
            {{ $t('wz_case_form.service_order_data.imei_sn') }}
          </div>
          <div class="half-forth-part-value value" >
            {{ wz_case.sn_imei_1 }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-ms-12 col-md-4 col-xxl-4 col-lg-4 decision-time-counter">
      <app-decision-time-counter-new :timer="timer" :version="'large'"/>
    </div>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import {Wz1FormProcess} from "@/mixins/Wz1FormProcess";

export default {
  name: "ServiceOrderDataNew",
  computed: {
    ...mapGetters('WzCase', {wz_case: 'wz_case'}),
  },
  mixins: [Wz1FormProcess],
}
</script>

<style scoped>
.hr-ccs-thin {
  border-bottom: 0.01em solid #B4B4B4;
}
.ml-5 {
  margin-left: 15px;
}
.fs-16 {
  font-size: 16px;
}
.w20px {
  width: 40px;
}
.service-order-data {
  background: rgb(91, 143, 122);
  background: linear-gradient(90deg, rgba(91, 143, 122, 1) 25%, rgba(119, 192, 163, 1) 100%);
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 25px 0 25px 26px;
  color: #ffffff;
  font-family: Mulish;
  font-size: 16px;
  line-height: 20.08px;
  text-align: left;
}
.value {
  font-weight: 700;
}

.pr-0{
  padding-right: 0;
}
@media (max-width: 768px) {
  #service-order-data {
    padding: 0;
    margin: 0;
  }
  #service-order-data.row {
    padding-right: 0;
  }
  .half-forth-part-label {
    width: 50%;
  }
  .half-forth-part-value {
    width: 50%;
  }
  .service-order-data {
    padding: 26px;
  }
  .show-desktop {
    display: none;
  }
  .show-mobile {
    display: inline-block;
  }
}
@media (max-width: 1200px) {
  #service-order-data .card-body {
    font-size: 12px;
  }
}
@media (min-width: 768px) {
  .half-forth-part-label {
    width: 25%;
  }
  .half-forth-part-value {
    width: 25%;
  }
  .show-desktop {
    display: inline-block;
  }
  .show-mobile {
    display: none;
  }
  .decision-time-counter {
    position: relative;
    padding-right: 0;
  }
  .row1 {
    margin-bottom: 10px;
  }
}
</style>