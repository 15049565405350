<template>
  <div id="service-order-data" class="h-100">
    <div  class="card ccs-card-radius border-0 w-100 mb-0 pb-2">
      <div v-if="wz_case.pattern_email === 'I'">
        <div class="card-body mt-3 mb-0 pb-0">
          <p class="card-title mulish ml-5 mb-0 pb-0 fw-bold">{{ $t('wz_case_form.service_order_data.add_films') }}</p>
        </div>
        <hr class="hr-ccs-thin">
        <div class="card-body">
          <div class="card-body mt-0 pt-0 mb-0 pb-0">
            <div class="fw-bolder subtitle">{{ $t('wz_case_form.service_order_data.make_film') }}</div>
          </div>
          <div class="card-body col col-xxl-12 col-lg-12 order-xxl-2 order-md-3 pb-0">
            <div class="input-file-field">
              <app-file_upload
                  v-on:getDroppedFiles="dropFiles($event);"
                  ref="customer_group_update_form"
                  :mp4="true"/>
            </div>
          </div>
          <div class="card-body error-block pb-0 pt-0" v-if="v_error">
            <div class="alert-error" role="alert">
              <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6591 1.48148C12.5186 -0.493826 9.6675 -0.493829 8.52705 1.48148L0.401382 15.5556C-0.739062 17.5309 0.686492 20 2.96738 20H19.2187C21.4996 20 22.9252 17.5309 21.7847 15.5556L13.6591 1.48148ZM9.23145 6.00195L10.1824 12.5627L12.0049 12.5618L12.9494 6.00012L9.23145 6.00195ZM9.55579 13.2628L9.55727 16.281L12.6337 16.2794L12.6322 13.2613L9.55579 13.2628Z" fill="#FF0000"/>
              </svg>
              {{ v_validation_error }}
            </div>
          </div>
          <div class="card-body subtitle mt-0 pt-0 mb-0 pb-0">
            <div class="fw-bolder">
              <p class="fs-4-ccs-special text-left text-sm-justify mt-4 mb-3">{{ $t('wz_case_form.wz2_form.films_absence') }}</p>
            </div>
            <div>
              <div class="form-check mb-2 no-films">
                <input v-model="params.decision_answer"
                       :value="this.$t('wz_case_form.wz2_form.no_films_value')"
                       id="decision_answer_1"
                       class="form-check-input decision_answer"
                       type="checkbox"
                       @change="checkedOnClick('decision_answer_1', 'decision_answer');">
                <label class="form-check-label" for="decision_answer_1">{{ $t('wz_case_form.wz2_form.no_films') }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="['AW', 'A', 'B'].includes(wz_case.pattern_email)">
        <div class="card-body mt-3 mb-0 pb-0">
          <p class="card-title mulish ml-5 mb-0 pb-0 fw-bold">{{ $t('wz_case_form.service_order_data.add_documents') }}</p>
        </div>
        <hr class="hr-ccs-thin">
        <div class="card-body pt-0">
          <div class="card-body pt-0 mb-0 pb-0">
            <div class="show-desktop mt-3">
              <p class="fw-bolder pb-0 mb-0 subtitle">{{ $t('wz_case_form.service_order_data.make_scan_1') }}</p>
              <p class="fw-bolder pb-0 mb-0 subtitle">{{ $t('wz_case_form.service_order_data.make_scan_2') }}:</p>
            </div>
            <div class="fw-bolder subtitle show-mobile mt-2">{{ $t('wz_case_form.service_order_data.make_scan_mobile') }}</div>
          </div>
          <div class="card-body col col-xxl-12 col-lg-12 order-xxl-2 order-md-3 pb-0">
            <div class="input-file-field">
              <app-file_upload
                  v-on:getDroppedFiles="dropFiles($event);"
                  ref="customer_group_update_form"/>
            </div>
          </div>
          <div class="card-body error-block pb-0 pt-0" v-if="v_error">
            <div class="alert-error" role="alert">
              <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6591 1.48148C12.5186 -0.493826 9.6675 -0.493829 8.52705 1.48148L0.401382 15.5556C-0.739062 17.5309 0.686492 20 2.96738 20H19.2187C21.4996 20 22.9252 17.5309 21.7847 15.5556L13.6591 1.48148ZM9.23145 6.00195L10.1824 12.5627L12.0049 12.5618L12.9494 6.00012L9.23145 6.00195ZM9.55579 13.2628L9.55727 16.281L12.6337 16.2794L12.6322 13.2613L9.55579 13.2628Z" fill="#FF0000"/>
              </svg>
              {{ v_validation_error }}
            </div>
          </div>
          <div class="card-body subtitle mt-4 pt-0 mb-0 pb-0">
            <div class="fw-bolder mb-4" v-if="wz_case.pattern_email === 'B'">
              <p class="fs-4-ccs-special text-left text-sm-justify mt-1 mb-1">{{ $t('wz_case_form.wz2_form.documents_absence_one') }}</p>
            </div>
            <div class="fw-bolder mb-4" v-else>
              <p class="fs-4-ccs-special text-left text-sm-justify mt-1 mb-1">{{ $t('wz_case_form.wz2_form.documents_absence_many') }}</p>
            </div>
            <div>
              <div class="form-check mb-2">
                <input v-model="params.decision_answer"
                       :value="this.$t('wz_case_form.wz2_form.free_send')"
                       id="decision_answer_1"
                       class="form-check-input decision_answer"
                       type="checkbox"
                       @change="checkedOnClick('decision_answer_1', 'decision_answer');">
                <label class="form-check-label" for="decision_answer_1">{{ $t('wz_case_form.wz2_form.free_send') }}</label>
              </div>
              <div v-if="wz_case.pattern_email === 'AW'">
                <div class="form-check mb-2">
                  <input v-model="params.decision_answer"
                         id="decision_answer_2"
                         :value="this.$t('wz_case_form.wz2_form.paid_answer')"
                         class="form-check-input decision_answer"
                         type="checkbox"
                         @change="checkedOnClick('decision_answer_2', 'decision_answer');">
                  <label class="form-check-label" for="decision_answer_2">{{ $t('wz_case_form.wz2_form.paid_answer') }}</label>
                </div>
              </div>
              <div v-if="wz_case.pattern_email === 'A'">
                <div class="form-check mb-2">
                  <input v-model="params.decision_answer"
                         id="decision_answer_3"
                         :value="this.$t('wz_case_form.wz2_form.repair_price_one')"
                         class="form-check-input decision_answer"
                         type="checkbox"
                         @change="checkedOnClick('decision_answer_3', 'decision_answer');">
                  <label class="form-check-label" for="decision_answer_3">
                    {{ $t('wz_case_form.wz2_form.repair_price_one') }}
                  </label>
                  <span class="show-desktop" onclick="$('.additional_info').toggle();">
                    <svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21px" height="21px" viewBox="0 0 416.979 416.979" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85 c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786 c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576 c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765 c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"></path> </g> </g></svg>
                  </span>
                  <span class="show-mobile" @click="showAdditionalPopup()">
                    <svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21px" height="21px" viewBox="0 0 416.979 416.979" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85 c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786 c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576 c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765 c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"></path> </g> </g></svg>
                  </span>
                </div>
                <div class="additional_info">
                  <b>{{ $t('wz_case_form.wz2_form.warning_start') }} </b>{{ $t('wz_case_form.wz2_form.repair_price_text_one') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="wz_case.pattern_email === 'C'">
        <div class="card-body mt-3 mb-0 pb-0">
          <p class="card-title ml-5 mb-0 pb-0 fw-bold">{{ $t('wz_case_form.wz2_form.pattern_C_title') }}</p>
        </div>
        <hr class="hr-ccs-thin">
        <div v-if="wz_case.fax_content.includes('IMEI')" class="card-body">
          <div class="card-body mt-0 pt-0 mb-0 pb-0">
            <div class="col-xxl-3 col-lg-3 col-md-6 col-sm-12">
              <input v-if="v_error" v-model="params.imei" ref="imei" class="mb-3 input-group-text border-2" type="text" id="imei"
                     minlength="15" maxlength="15" :placeholder="this.$t('wz_case_form.wz2_form.label_imei')" @keyup="checkButton">
              <input v-else v-model="params.imei" ref="imei" class="mb-3 input-group-text border-2" type="text" id="imei"
                     minlength="15" maxlength="15" :placeholder="this.$t('wz_case_form.wz2_form.label_imei')" @keyup="checkButton">
            </div>
          </div>
          <div class="card-body error-block pb-0 pt-0" v-if="v_error">
            <div  class="alert-error" role="alert">
              <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6591 1.48148C12.5186 -0.493826 9.6675 -0.493829 8.52705 1.48148L0.401382 15.5556C-0.739062 17.5309 0.686492 20 2.96738 20H19.2187C21.4996 20 22.9252 17.5309 21.7847 15.5556L13.6591 1.48148ZM9.23145 6.00195L10.1824 12.5627L12.0049 12.5618L12.9494 6.00012L9.23145 6.00195ZM9.55579 13.2628L9.55727 16.281L12.6337 16.2794L12.6322 13.2613L9.55579 13.2628Z" fill="#FF0000"/>
              </svg>
              {{ v_validation_error }}
            </div>
          </div>
        </div>
        <div v-if="wz_case.fax_content.includes('S/N')" class="card-body">
          <div class="card-body mt-0 pt-0 mb-0 pb-0">
            <div class="col-xxl-3 col-lg-3 col-md-6 col-sm-12">
              <input v-if="v_error" v-model="params.sn" ref="sn" class="mb-3 input-group-text border-2" type="text" id="sn"
                     maxlength="20" :placeholder="this.$t('wz_case_form.wz2_form.label_sn')" @keyup="checkButton">
              <input v-else v-model="params.sn" ref="sn" class="mb-3 input-group-text border-2" type="text" id="sn"
                     maxlength="20" :placeholder="this.$t('wz_case_form.wz2_form.label_sn')" @keyup="checkButton">
            </div>
          </div>
          <div class="card-body error-block pb-0 pt-0" v-if="v_error">
            <div  class="alert-error" role="alert">
              <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6591 1.48148C12.5186 -0.493826 9.6675 -0.493829 8.52705 1.48148L0.401382 15.5556C-0.739062 17.5309 0.686492 20 2.96738 20H19.2187C21.4996 20 22.9252 17.5309 21.7847 15.5556L13.6591 1.48148ZM9.23145 6.00195L10.1824 12.5627L12.0049 12.5618L12.9494 6.00012L9.23145 6.00195ZM9.55579 13.2628L9.55727 16.281L12.6337 16.2794L12.6322 13.2613L9.55579 13.2628Z" fill="#FF0000"/>
              </svg>
              {{ v_validation_error }}
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="wz_case.pattern_email === 'D'">
        <div class="card-body mt-3 mb-0 pb-0">
          <p class="card-title ml-5 mb-0 pb-0 fw-bold">{{ $t('wz_case_form.wz2_form.pattern_D_title') }}</p>
        </div>
        <hr class="hr-ccs-thin">
        <div class="card-body">
          <div class="card-body mt-0 pt-0 mb-0 pb-0">
            <div class="col-xxl-12 col-lg-12 col-md-12 col-sm-12">
              <textarea v-model="params.fault_description" ref="fault_description" rows="4" class="textarea-field" @keyup="checkButton"
                        :placeholder="this.$t('wz_case_form.wz2_form.fault_description')" style="display: block" maxlength="500"></textarea>
            </div>
          </div>
          <div class="card-body error-block pb-0 pt-0 mt-3" v-if="v_error">
            <div  class="alert-error" role="alert">
              <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6591 1.48148C12.5186 -0.493826 9.6675 -0.493829 8.52705 1.48148L0.401382 15.5556C-0.739062 17.5309 0.686492 20 2.96738 20H19.2187C21.4996 20 22.9252 17.5309 21.7847 15.5556L13.6591 1.48148ZM9.23145 6.00195L10.1824 12.5627L12.0049 12.5618L12.9494 6.00012L9.23145 6.00195ZM9.55579 13.2628L9.55727 16.281L12.6337 16.2794L12.6322 13.2613L9.55579 13.2628Z" fill="#FF0000"/>
              </svg>
              {{ v_validation_error }}
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="wz_case.pattern_email === 'E'">
        <div class="card-body mt-3 mb-0 pb-0">
          <p class="card-title mulish ml-5 mb-0 pb-0">{{ $t('wz_case_form.wz2_form.pattern_E_title') }}</p>
        </div>
        <hr class="hr-ccs-thin">
        <div class="card-body">
          <div class="card-body mt-0 pt-0 mb-3 pb-0">
            <p class="card-subtitle">{{ $t('wz_case_form.wz2_form.pattern_E') }}</p>
          </div>
          <div class="card-body error-block pb-0 pt-0" v-if="v_error">
            <div  class="alert-error" role="alert">
              <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6591 1.48148C12.5186 -0.493826 9.6675 -0.493829 8.52705 1.48148L0.401382 15.5556C-0.739062 17.5309 0.686492 20 2.96738 20H19.2187C21.4996 20 22.9252 17.5309 21.7847 15.5556L13.6591 1.48148ZM9.23145 6.00195L10.1824 12.5627L12.0049 12.5618L12.9494 6.00012L9.23145 6.00195ZM9.55579 13.2628L9.55727 16.281L12.6337 16.2794L12.6322 13.2613L9.55579 13.2628Z" fill="#FF0000"/>
              </svg>
              {{ v_validation_error }}
            </div>
          </div>
          <div class="card-body mt-0 pt-0 mb-0 pb-0">
            <div class="row">
              <div class="col-xxl-3 col-lg-3 col-md-12 col-sm-12">
                <div class="form-check mt-2">
                  <input v-model="params.complaint_type"
                         :value="this.$t('wz_case_form.wz2_form.select_E_option_1')"
                         id="complaint_type_1" checked="checked"
                         class="form-check-input complaint_type"
                         type="checkbox"
                         @change="checkedOnClick('complaint_type_1', 'complaint_type');">
                  <label class="form-check-label" for="complaint_type_1">{{ $t('wz_case_form.wz2_form.select_E_option_1') }}</label>
                </div>
                <div class="form-check mt-2">
                  <input v-model="params.complaint_type"
                         :value="this.$t('wz_case_form.wz2_form.select_E_option_2')"
                         id="complaint_type_2"
                         class="form-check-input complaint_type"
                         type="checkbox"
                         @change="checkedOnClick('complaint_type_2', 'complaint_type');">
                  <label class="form-check-label" for="complaint_type_2">{{ $t('wz_case_form.wz2_form.select_E_option_2') }}</label>
                </div>
                <div class="form-check mt-2">
                  <input v-model="params.complaint_type"
                         :value="this.$t('wz_case_form.wz2_form.select_E_option_3')"
                         id="complaint_type_3"
                         class="form-check-input complaint_type"
                         type="checkbox"
                         @change="checkedOnClick('complaint_type_3', 'complaint_type');">
                  <label class="form-check-label" for="complaint_type_3">{{ $t('wz_case_form.wz2_form.select_E_option_3') }} &nbsp;
                    <button class="show-desktop show-addition-info" onclick="$('.additional_info_e').toggle();">
                      <svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21px" height="21px" viewBox="0 0 416.979 416.979" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85 c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786 c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576 c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765 c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"></path> </g> </g></svg>
                    </button>
                    <button class="show-mobile show-addition-info" @click="showAdditionalPopupEPattern()">
                      <svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21px" height="21px" viewBox="0 0 416.979 416.979" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85 c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786 c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576 c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765 c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"></path> </g> </g></svg>
                    </button>
                  </label>
                </div>
                <div class="form-check mt-2">
                  <input v-model="params.complaint_type"
                         :value="this.$t('wz_case_form.wz2_form.select_E_option_4')"
                         id="complaint_type_4"
                         class="form-check-input complaint_type"
                         type="checkbox"
                         @change="checkedOnClick('complaint_type_4', 'complaint_type');">
                  <label class="form-check-label" for="complaint_type_4">{{ $t('wz_case_form.wz2_form.select_E_option_4') }}</label>
                </div>
              </div>
              <div class="col-xxl-8 col-lg-8 col-md-12 col-sm-12 additional_info_e p-2">
                <b>{{ $t('wz_case_form.wz2_form.warning_start') }}&nbsp; </b>{{ $t('wz_case_form.wz2_form.warning_E') }}
              </div>
            </div>

          </div>
        </div>
      </div>

      <div v-if="wz_case.pattern_email === 'H'">
        <div class="card-body mt-3 mb-0 pb-0">
          <p class="card-title ml-5 mb-0 pb-0 fw-bold font-12">{{ $t('wz_case_form.wz2_form.pattern_H_title') }}</p>
        </div>
        <div class="card-body mb-0 pb-0">
          <p class="card-title ml-5 mb-0 pb-0 fw-bold">{{ $t('wz_case_form.wz2_form.pattern_H') }}</p>
        </div>
        <hr class="hr-ccs-thin">
        <div class="card-body">
          <div class="card-body error-block pb-0 pt-0" v-if="v_error">
            <div  class="alert-error" role="alert">
              <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6591 1.48148C12.5186 -0.493826 9.6675 -0.493829 8.52705 1.48148L0.401382 15.5556C-0.739062 17.5309 0.686492 20 2.96738 20H19.2187C21.4996 20 22.9252 17.5309 21.7847 15.5556L13.6591 1.48148ZM9.23145 6.00195L10.1824 12.5627L12.0049 12.5618L12.9494 6.00012L9.23145 6.00195ZM9.55579 13.2628L9.55727 16.281L12.6337 16.2794L12.6322 13.2613L9.55579 13.2628Z" fill="#FF0000"/>
              </svg>
              {{ v_validation_error }}
            </div>
          </div>
          <div class="card-body mt-0 pt-0 mb-0 pb-0 d-flex">
            <div class="col-xxl-12 col-lg-12 col-md-12 col-sm-12">
              <div class="form-check mt-2">
                <input v-model="params.memory_info"
                       :value="this.$t('wz_case_form.wz2_form.pattern_H_checkbox_value_one')"
                       id="memory_info_1" checked="checked"
                       class="form-check-input memory_info"
                       type="checkbox"
                       @change="checkedOnClick('memory_info_1', 'memory_info');">
                <label class="form-check-label" for="memory_info_1"><b>{{ $t('wz_case_form.wz2_form.pattern_H_checkbox_yes') }}</b>{{ $t('wz_case_form.wz2_form.pattern_H_checkbox_one') }}</label>
              </div>
              <div class="form-check mt-2">
                <input v-model="params.memory_info"
                       :value="this.$t('wz_case_form.wz2_form.pattern_H_checkbox_value_two')"
                       id="memory_info_2" checked="checked"
                       class="form-check-input memory_info"
                       type="checkbox"
                       @change="checkedOnClick('memory_info_2', 'memory_info');">
                <label class="form-check-label" for="memory_info_2" disabled="disabled"><b>{{ $t('wz_case_form.wz2_form.pattern_H_checkbox_no') }}</b>{{ $t('wz_case_form.wz2_form.pattern_H_checkbox_two') }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body mt-4">
      <button v-if="showButton" class="send" @click="verifyForm()"> {{ $t('wz_case_form.wz2_form.send') }}</button>
      <button v-else class="send disabled" disabled="disabled"> {{ $t('wz_case_form.wz2_form.send') }}</button>
    </div>
  </div>

  <app-confirmation-modal-new-style
      :message="confirm_message"
      ref="confirmationModal"
      @confirm="saveWz2Form"
  />
  <app-additional-info-modal
      :message="additional_info_message"
      ref="additionalInfoModal"
      @close="close()"
  />
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {Wz2FormProcess} from "@/mixins/Wz2FormProcess";
import {Wz1FormValidator} from "@/mixins/Wz1FormValidator";
import {Wz2FormValidator} from "@/mixins/Wz2FormValidator";
export default {
  name: "Wz2Details",
  data() {
    return {
      additional_info: false,
      params: this.getDefaultParams(),
      showButton: false,
      confirm_message: "Czy na pewno chcesz przesłać odpowiedź?",
      additional_info_message: ''
    }
  },
  mixins: [Wz2FormProcess, Wz1FormValidator, Wz2FormValidator],
  created() {
    if (this.wz_case.wz_number === 'WZ2') {
      document.getElementById('footer').style.display = 'none';
    }
  },
  mounted() {
    this.params.current_form = this.wz_case.pattern_email;
    this.params.fax_id = this.wz_case.fax_id;
  },
  computed: {
    ...mapGetters('Wz2Form', {wz2_form: 'wz2_form'}),
    ...mapGetters('WzCase', {wz_case: 'wz_case', wz_case_decision: 'wz_case_decision'}),
  },
  methods: {
    ...mapActions('Wz2Form', {updateWz2Form: 'updateWz2Form'}),
    getDefaultParams() {
      return {
        decision: '',
        complaint_type: '',
        imei: '',
        sn: '',
        files: [],
        fault_description: '',
        memory_info: '',
        decision_answer: '',
        current_form: '',
        fax_id: ''
      }
    },
    showConfirmation() {
      this.$refs.confirmationModal.show();
    },
    verifyForm() {
      if (this.validateWz2BeforeShowSendButton()) {
        this.showConfirmation();
      }
    },
    checkedOnClick(el_id, class_name){
      this.v_error = false;
      let checkedElement = document.getElementById(el_id);
      let checkedElementInfo = checkedElement.checked;
      let checkboxesList = document.getElementsByClassName(class_name);
      for (let i = 0; i < checkboxesList.length; i++) {
        checkboxesList.item(i).checked = false;
      }
      this.showButton = false;
      if (checkedElementInfo === true) {
        checkedElement.checked = true;
        this.writeDecision(checkedElement.value, class_name);
        this.showButton = true;
      } else {
        this.params.decision = '';
      }
    },
    dropFiles(value) {
      this.params.files = value;
      if (this.params.files.length > 0) {
        this.showButton = true;
      } else {
        if (this.params.decision === '') {
          this.showButton = false;
        }
      }
    },
    writeDecision(value, class_name) {
      switch (class_name) {
        case 'complaint_type':
          this.params.decision = 'Zgłaszam reklamację z tytułu: ' + value;
          break;
        case 'decision_answer':
        case 'memory_info':
          this.params.decision = value;
          break;
      }
    },
    checkButton() {
      this.showButton = this.params.imei !== '' || this.params.sn !== '' || this.params.fault_description !== '';
    },
    showAdditionalPopup() {
      this.additional_info_message = this.$t('wz_case_form.wz2_form.repair_price_text_one')
      this.$refs.additionalInfoModal.show();
    },
    showAdditionalPopupEPattern() {
      this.additional_info_message = this.$t('wz_case_form.wz2_form.warning_E')
      this.$refs.additionalInfoModal.show();
    }
  }
}
</script>

<style scoped>
  #additional-elements .form-switch input,
  #additional-elements .form-switch label {
    cursor: pointer;
  }
  #recommendations-table tr {
    margin-bottom: 10px;
  }
  #recommendations-table td {
    padding: 0.25rem 0.5rem;
    margin: 0;
  }
  #recommendations-table td p {
    margin: 0;
  }

  .ccs-card table {
    margin-bottom: 0.5rem!important;
  }

  .ccs-card .footer table {
    border-collapse: separate;
    border-spacing: 8px 0;
  }

  .ccs-card .footer table td {
    padding: 0!important;
  }

  @media (min-width: 576px) {
    .text-sm-justify {
      text-align: justify;
    }
  }
  .hr-ccs-thin {
    border: 0.01em solid #B4B4B4
  }
  .ml-5 {
    margin-left: 15px;
  }
  .input-group-text {
    width: 100%;
    background: none;
    border-radius: 10px;
  }
  .card-title {
    font-family: Mulish;
    font-size: 20px;
    font-weight: 700;
    line-height: 25.1px;
    text-align: left;
    color: #4E4E4E;
  }
  .card-subtitle {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 700;
    line-height: 17.57px;
    text-align: left;
    color: #4E4E4E;
  }
  .form-check-input {
    width: 15px;
    height: 15px;
  }
  .additional_info, .additional_info_e {
    display: none;
    margin-top: 20px;
    border-radius: 20px;
    border: 2px solid #23AE84;
    align-content: center;
    justify-content: center;
    padding: 10px;
  }
  .additional_info_e {
    display: none;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-top: 50px;
  }
  .textarea-field {
    border: 2px solid #E6E6E6;
    border-radius: 20px;
    width: 100%;
    padding: 10px;
    color: #B4B4B4;
  }
  .textarea-field::-webkit-resizer{color: pink; }
  .disabled {
    opacity: 0.5;
  }
  .ccs-card-radius {
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
  }
  .form-check-label {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.08px;
    text-align: left;
    color: #4E4E4E;
  }
  @media (max-width: 768px) {
    .card-title {
      font-size: 14px;
      line-height: 18px;
    }
    .additional_info {
      font-size: 12px;
      display: none;
    }
    .font-12 {
      font-size: 12px;
    }
    .form-check {
      margin-bottom: 20px;
    }
    .form-check label {
      font-size: 14px;
    }
    .subtitle {
      font-family: Mulish;
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      text-align: left;

    }
    .send {
      width: 80%;
      height: 42px;
      border-radius: 20px;
      background-color: #23AE84;
      color: #ffffff;
      border: none;
      font-weight: 600;
      font-size: 14px;
      margin-left: 10%;
      margin-right: 10%;
    }
    .show-desktop {
      display: none;
    }
    .show-mobile {
      display: inline-block;
    }
    .error-block {
      min-height: 35px;
    }
  }
  @media (min-width: 768px) {
    .subtitle {
      font-family: Mulish;
      font-size: 14px;
      font-weight: 700;
      line-height: 23px;
      text-align: left;

    }
    .font-12 {
      font-size: 16px;
    }
    .form-check label {
      font-size: 16px;
    }
    .send {
      width: 106px;
      height: 42px;
      border-radius: 20px;
      background-color: #23AE84;
      color: #ffffff;
      border: none;
      font-weight: bold;
      font-size: 14px;
      float: right;
    }
    .show-desktop {
      display: inline-block;
    }
    .show-mobile {
      display: none;
    }
    .additional_info {
      font-weight: 600;
    }
    .error-block {
      min-height: 25px;
    }
  }
  .form-check .form-check-input {
    margin-left: -25px;
  }
  .form-check-input {
    width: 17px;
    height: 17px;
  }
  #decision_answer_1, #decision_answer_2, #decision_answer_3 {
    border: 2px solid #cfcccc;
  }
  .hr-ccs-thin {
    border: 0.33px solid #B4B4B4
  }
  .alert-error {
    color: red;
    font-family: Mulish;
    font-size: 16px;
    position: absolute;
    right: 0;
    top: -10px;
    padding-right: 17px;
    padding-left: 17px;
  }
  .error-block {
    position: relative;
    height: 25px;
  }
  .show-addition-info {
    border: none;
    background: none;
  }
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
  .no-films {
    padding-left: 0;
  }
  .no-films .decision_answer {
    margin-left: 0;
  }
  .no-films label {
    margin-left: 10px;
  }
</style>
